import React, { useState } from 'react';
import { Table, TableRow, TableCell, TableBody, Paper, TableContainer, TableHead } from "@material-ui/core";
import EventRow from './EventRow';
import { sports } from '../../../../../helpers/constant';
import { makeStyles } from "@material-ui/core/styles";
import BetSpinner from '../../../../UI/Spinner/BetSpinner'

const useStyles = makeStyles((theme) => ({

    row: {
        display: 'grid',
        gridTemplateColumns: '46% 18% 18% 18%',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '20% auto auto auto'
        }
    },
    head: {
        textAlign: 'center',
        padding: '5px',
        fontSize: 'var(--medium_font)',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: 'var(--small_font)',
        }
    }
}))

const Event = (props) => {
    const classes = useStyles()
    const matches = props?.matches?.filter(match=>match?.sport === props.id)

    const loader = <TableRow><TableCell colSpan={4}><BetSpinner /></TableCell></TableRow>
    let allMatches = loader

    if (matches) {
        if (matches.length) {

            allMatches = matches.map(match => {

                return <EventRow
                    key={match.marketId}
                    marketId={match.marketId}
                    marketStartTime={match.marketStartTime}
                    name={match.matchName}
                    matchId={match.matchId}
                    sport={props.id}
                    cupRate={match.cupRate}
                    odds={match.odds ? match.odds[0] : {}}
                    inplay={match.odds && match.odds.length ? match.odds[0].inplay : false}
                    noOfRunners={match.odds && match.odds.length ? (match.odds[0].runners ? match.odds[0].runners.length : 0) : 0} />
            }).filter(match => match !== null)

            if (!allMatches.length) {
                allMatches = loader
            }
        } else {
            allMatches = <TableRow><TableCell align='center'>No {sports.filter(sport => sport.id === props.id)[0]?.name} Matches</TableCell></TableRow>
        }
    }

    return <TableContainer component={Paper}>
        <Table style={{width: '100%'}}>
            <TableHead>
                <TableRow className={classes.row}>
                    <TableCell className={classes.head}></TableCell>
                    <TableCell className={classes.head}>1</TableCell>
                    <TableCell className={classes.head}>X</TableCell>
                    <TableCell className={classes.head}>2</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allMatches}
            </TableBody>
        </Table>
    </TableContainer>

}

export default Event