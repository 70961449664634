import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux'
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: "10px",
    width: 240,
    '& input': {
      fontSize: 'var(--large_font)',
    },
    [theme.breakpoints.down("sm")]: {
      '& input': {
        fontSize: 'var(--small_font)',
      },
    },
  },
  radioText: {
    '& .MuiFormControlLabel-label': {
      fontSize: 'var(--large_font)',
    },
    [theme.breakpoints.down("sm")]: {
      '& .MuiFormControlLabel-label': {
        fontSize: 'var(--small_font)',
      }
    }
  },
  radioButton: {
    '& .MuiSvgIcon-root': {
        fontSize: 'var(--very_large_font)'
    },
    [theme.breakpoints.down("sm")]: {
      '& .MuiSvgIcon-root': {
        fontSize: 'var(--large_font)'
      },
    }
  },
  button: {
    marginTop: "10px",
  },
  heading: {
    margin: '0 0 10px',
    fontWeight: 'bold',
    color: 'var(--heading_color)',
    [theme.breakpoints.down("sm")]: {
      marginTop: '10px',
      fontSize: 'var(--small_font)',
    }
  }
}));

const today = moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss");
const tomorrow = moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss");

export default function DateTime(props) {
  
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const initialFrom = props.dateCache?.from || queryParams.get('from') || today;
  const initialTo = props.dateCache?.to || queryParams.get('to') || tomorrow;

  const classes = useStyles();
  const username = useSelector(state => state.auth.username)
  const [from, setFrom] = useState(initialFrom);
  const [to, setTo] = useState(initialTo);
  const [value, setValue] = useState("All");

  const handleToChange = (event) => {
    setTo(event.target.value);
  };
  const handleFromChange = (event) => {
    setFrom(event.target.value);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    props.onSubmit(from, to, value);
  }, [from, to, value, username, props.userInfo ? props.userInfo : null]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("from", from);
    queryParams.set("to", to);
    history.replace({ search: queryParams.toString() });
  }, [from, to, location.search, history]);

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel className={classes.heading}>{props.name}</FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
          className={classes.radioText}
        >
          {props.filter.map(obj => {
            return <FormControlLabel
              value={obj.value}
              control={<Radio className={classes.radioButton}/>}
              label={obj.label}
              key={obj.value} />
          })}
        </RadioGroup>
      </FormControl>
      <div className={classes.container}>
        <TextField
          name="from"
          label="From"
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          value={from}
          onChange={handleFromChange}
          className={classes.textField}
        />
        <TextField
          name="to"
          label="To"
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleToChange}
          value={to}
          className={classes.textField}
        />
      </div>
    </>
  );
}
