import * as actionTypes from './actionTypes';

export const updateBalanceOrExposure = (balance,exposure) => {
    return {
        type: actionTypes.UPDATE_BALANCE,
        balance: balance,
        exposure: exposure
    };
};

export const updateCurrentBets = (bet) => {
    return {
        type: actionTypes.UPDATE_CURRENT_BETS,
        bet: bet
    };
};

export const setBettingStatus = () => {
    return {
        type: actionTypes.SET_BETTING_STATUS
    };
};

export const resetBettingStatus = () => {
    return {
        type: actionTypes.RESET_BETTING_STATUS
    };
};
