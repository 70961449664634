import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import { useParams } from 'react-router-dom'
import { getTvUrl } from '../../../../../helpers/common';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
        margin: '0 auto',
        paddingLeft: '10px',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            display: "none",
        }
    },
    heading: {
        background: "var(--secondary)",
        height: "30px",
        color: "var(--header_color)",
        display: 'flex',
        fontWeight: 700,
        fontSize: 'var(--large_font)',
        alignItems: 'center',
        padding: '1px 7px',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '97%',
            fontSize: 'var(--small_font)',
        }
    },
    frame: {
        width: '100%',
        height: '220px'
    },
    icon : {
        marginLeft: '5px',
        fontSize: '22px'
    }
}));

const LiveTv = () => {

    const classes = useStyles()
    const params = useParams()
    const [showTv, setShowTv] = useState(false)

    const toggleTv = () => {
        setShowTv(prevValue=>!prevValue)
    }

    return <div className={classes.root}>
        <div className={classes.heading} onClick={toggleTv}>Live Tv <LiveTvIcon className={classes.icon}/></div>
        {showTv && <iframe
            id="myframe"
            className={classes.frame} 
            src={getTvUrl(params.matchId)}
            allow="autoplay"
            style={{border: "none"}}
            
        />}
    </div>
}

export default LiveTv