import React from 'react'
import logo from '../../../assets/images/ball-loader.gif'
import classes from './BetSpinner.module.css'

export default function BetSpinner() {
    return (
        <div className={classes.cent}>
            <img src={logo} alt='Loader' height='80px'/>
        </div>
    )
}
