import React, { useState, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

const CurrentBets = (props) => {
   
    const users = [
        { type: "seniorsuper", usertype: "2" },
        { type: "supermaster", usertype: "3" },
        { type: "master", usertype: "4" }
    ]
    const initialColumnDefs = [
        props.myUsertype !== '5' ? { field: 'username', width: 135, sortable: true, filter: true } : null,
        { field: 'odds', width: 90, sortable: true },
        { field: 'stake', width: 90, sortable: true },
        { field: 'runner', width: 330, sortable: true, filter: true },
        props.myUsertype === '1' ? { field: 'bet_id', width: 90, sortable: true } : null,
        ...users
            .filter(user => parseFloat(user.usertype) > parseFloat(props.myUsertype))
            .map(user => ({ field: user.type, width: 120, key: user.usertype, sortable: true })),
        { field: 'placed_at', width: 330, sortable: true }
    ].filter(Boolean); // Remove null values

    const [columnDefs] = useState(initialColumnDefs);

    const gridOptions = {

        // all even rows assigned 'my-shaded-effect'
        getRowStyle: params => {
            if(params.node.data.code === 1){
                return { backgroundColor: 'var(--light_green)' }
            } else if (params.node.data.selection === 'back') {
                return { backgroundColor: 'var(--odds_blue)' }
            } else {
                return { backgroundColor: 'var(--odds_pink)' }
            }
        },

        // other grid options ...
    }

    return (
        <div className="ag-theme-quartz" style={{ height: 400, width: "100%" }}>
            <AgGridReact
                gridOptions={gridOptions}
                rowData={props.bets}
                columnDefs={columnDefs}
            />
        </div>
    )
}

export default memo(CurrentBets);