import React, {useEffect, useState, memo} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { getDeviceType, getTvUrl, parseDateString } from '../../../../../../helpers/common';
import { getSportsRadarId } from '../../../../../../helpers/common';
import LiveTvIcon from '@material-ui/icons/LiveTv'
import SportsScoreIcon from '@material-ui/icons/Score';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Typography>{children}</Typography>
          )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '15px',
    [theme.breakpoints.down("sm")]: {
        margin: '10px 0 15px',
    },
  },
  heading: {
    color: 'var(--header_color)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px',
    fontSize: 'var(--large_font)',
    fontWeight: 700,
    backgroundClip: 'border-box',
    backgroundColor: "#fff",
    border: "1px solid rgba(0, 0, 0, .125)",
    [theme.breakpoints.down("sm")]: {
        fontSize: 'var(--small_font)',
    },
  },
  eventName:{
    display: 'flex',
    alignItems: 'center'
  },
  eventNameSpan:{
    [theme.breakpoints.down('sm')]: {
      maxWidth: '230px',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tvIcon: {
    fontSize: '18px',
    marginLeft: '4px'
  },
  left_heading: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        marginLeft: '5px',
        cursor: 'pointer'
    }
  },
  score: {
    width: '100%',
    height: '220px',
  },
  tabs: {
    background: 'var(--secondary)',
    color: 'var(--header_color)',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.4),
    fontSize: '19px',
  },
  tabWrapper: {
    flexDirection: 'row',
    textTransform: 'capitalize',
    fontSize: 'var(--large_font)',
    [theme.breakpoints.down("sm")]: {
      fontSize: 'var(--small_font)'
    }
  },
  active: {
    fontSize: 'var(--small_font)',
    textTransform: 'capitalize',
    fontWeight: 700,
    width: '50%',
    textAlign: 'center',
    backgroundColor: 'var(--heading)',
    color: '#fff',
  },
  inactive: {
    fontSize: 'var(--medium_font)',
    textTransform: 'capitalize',
    width: '50%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'var(--small_font)',
    }
  }
}));

function ScoreCard ({eventId, eventName, openDate, eventTypeId}) {

    const classes = useStyles()
    const [src, setSrc] = useState('')
    const [value, setValue] = useState(0);
    const [showTv, setShowTv] = useState(false)
    const isMobileView = getDeviceType() === 'mobile'

    const setScoreCardSource = async () => {
      const sports_radar_id = await getSportsRadarId(eventName, eventId)
      sports_radar_id && setSrc(`https://scorecard.oddstrad.com/get-scorecard-iframe/${eventTypeId}/${eventId}/${sports_radar_id}`)
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const toggleTV = () =>{
      setShowTv(prevValue=>!prevValue)
    }

    const MobileScoreCard = () => {

      if(!src){
        return <></>
      }

      return <div>
        <AppBar position="static" style={{marginTop: '8px'}}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              indicatorColor='primary'
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                  label="Score Card"
                  icon={<SportsScoreIcon className={classes.tabIcon}/>}
                  classes={{ wrapper: classes.tabWrapper }}
                  {...a11yProps(0)}
                  className={value === 0 ? classes.active : classes.inactive} />
              <Tab
                  label="TV"
                  icon={<LiveTvIcon className={classes.tabIcon}/>}
                  classes={{ wrapper: classes.tabWrapper }}
                  {...a11yProps(0)}
                  className={value === 1 ? classes.active : classes.inactive} />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} className={classes.myTab}>
          <iframe allow="autoplay" src={src} className={classes.score} style={{border: "none"}}></iframe>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.myTab}>
          <iframe 
            className={classes.score}
            allow="autoplay"
            src={getTvUrl(eventId)}
            style={{border: "none"}}
          />
        </TabPanel>
      </div>
    }

    const DesktopScoreCard = () => {
      if(!src){
        return <></>
      }

      return <iframe allow="autoplay" src={src} className={classes.score} style={{border: "none"}}></iframe>
    }

    useEffect(()=>{
      setScoreCardSource()
    }, [])

    return (
      <div>
        <div className={classes.root}>
          <div className={classes.heading}>
            <div className={classes.eventName}><span className={classes.eventNameSpan}>{eventName}</span>{isMobileView && !src && <LiveTvIcon className={classes.tvIcon} onClick={toggleTV}/>}</div>
            <span>{`${parseDateString(openDate).time}, ${parseDateString(openDate).date}`} </span>
          </div>
        </div>
        {isMobileView ? <MobileScoreCard/> : <DesktopScoreCard/>}
        {showTv && <iframe 
            className={classes.score} 
            src={getTvUrl(eventId)}
            allow="autoplay"
            style={{border: "none"}}
        />}
      </div>
    );
}

export default memo(ScoreCard)