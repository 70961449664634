import axios from '../../axios-instance/backendAPI'
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import * as actionTypes from './actionTypes';
import { localStorageGetItem } from '../../helpers/common';
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, username, usertype) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        username: username,
        usertype: usertype
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('IP');
    localStorage.removeItem('clients')
    localStorage.removeItem('pc')
    return {
        type: actionTypes.AUTH_LOGOUT,
        path: '/login'
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (username, password) => {
    return async dispatch => {
        dispatch(authStart());

        const authData = {
            username: username,
            password: password,
        };
        let url = '/user/login';

        axios.post(url, authData)
            .then(response => {

                if (response.data.success) {

                    alertify.success(response.data.message);
                    localStorage.setItem('token', response.data.token);
                    dispatch(authSuccess(response.data.token, username.toLowerCase(), response.data.usertype));
                    dispatch(setAuthRedirectPath('/dashboard'))
                } else {
                    alertify.error(response.data.message);
                    dispatch(authFail(response.data.message));
                }
            })
            .catch(err => {
                dispatch(authFail(err));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorageGetItem('token');
        if (!token) {
            dispatch(logout());
        } else {

            let url = '/user/authCheck'

            axios.get(url, { headers: { Authorization: 'Bearer ' + token } })
                .then(response => {

                    if (response.data.success) {
                        const {isExpired, username, usertype, timer} = response.data.data[0];

                        if (isExpired) {
                            dispatch(logout());
                        } else {
                            dispatch(authSuccess(token, username, usertype));
                            dispatch(checkAuthTimeout((timer) / 1000));
                            dispatch(setAuthRedirectPath('/dashboard'));
                        }
                    } else {
                        const {password_changed, user} = response.data;

                        if (password_changed) {
                            dispatch(authSuccess(token, user.username, user.usertype));
                            return dispatch(setAuthRedirectPath('/changePassword'));
                        }
                        dispatch(logout());
                    }
                })
                .catch(err => {
                    dispatch(authFail(err));
                })
        }
    };
};