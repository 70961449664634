import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button
} from "@material-ui/core"
import { useSelector } from 'react-redux'
import axios from '../../../../axios-instance/backendAPI'
import Modal from "../../../UI/Modal/Modal";
import Settlement from '../UserList/Actions/Settlement'
import { useHistory } from 'react-router-dom'
import { formatCoins } from '../../../../helpers/common'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 10px 10px 10px',
        fontSize: 'var(--large_font)',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)',
        },
    },
    tableContainer: {
        '& .MuiTableCell-root': {
            fontSize: 'var(--large_font)', // Adjust the font size as needed
        },
        [theme.breakpoints.down("sm")]: {
            '& .MuiTableCell-root': {
                fontSize: 'var(--small_font)', // Adjust the font size as needed
            },
        },
    },
    btn: {
        fontSize: 'var(--large_font)', // Adjust the font size as needed
        textTransform: 'capitalize',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)', // Adjust the font size as needed
        },
    },
    heading: {
        fontSize: 'var(--large_font)',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)',
        },
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 10px 0 0',
        alignItems: 'center'
    },
    box: {
        display: 'grid',
        gridTemplateColumns: '48% 4% 48%',
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: 'auto'
        },
    },
    button: {
        padding: '0'
    },
    link: {
        color: 'blue',
        cursor: 'pointer'
    },
    back: {
        cursor: 'pointer',
        fontWeight: 'bold'
    }
}))

export default function ChipSummary() {

    const classes = useStyles()
    const history = useHistory()
    const usertype = useSelector(state => state.auth.usertype)
    const username = useSelector(state => state.auth.username)
    const token = useSelector(state => state.auth.token)
    const [userInfo, setUserInfo] = useState({ username: '', usertype: '' })
    const [allUsers, setAllUsers] = useState([])
    const [userHistory, setUserHistory] = useState([])
    const [settleInfo, setSettleInfo] = useState({})
    const [updateRows, setUpdateRows] = useState(false)
    const [open, setOpen] = useState(false)

    const ahead = (username) => {

        if (userInfo.usertype !== '4') {
            let users = [...userHistory]
            users.push(username)
            setUserHistory(users)
            getUserInfo(username)
        }

    }

    const back = () => {

        if (userHistory.length !== 1) {
            const users = [...userHistory]
            users.pop()
            getUserInfo(users[users.length - 1])
            setUserHistory([...users])

        } else {
            history.goBack()
        }
    }
    const getUserInfo = (username) => {

        axios
            .get("/user/userBalanceInfo/" + username, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then((response) => {

                if (response.data.success) {
                    const user = {
                        username: response.data.data[0].username,
                        usertype: response.data.data[0].usertype
                    }
                    setUserInfo(user)
                }

            })
            .catch((err) => {
                console.log(err);
            });
    }

    const myInfo = () => {

        const user = {
            username: username,
            usertype: usertype
        }
        setUserInfo(user)
        const cur_user = []
        cur_user.push(username)
        setUserHistory(cur_user)
    }

    const settlement = (uplink, downlink, chips) => {
        setOpen(true)
        setSettleInfo({
            uplink: uplink,
            downlink: downlink,
            chips: chips
        })
    }

    const onClose = () => {
        setOpen(false)
    }

    const updateChips = () => {

        setUpdateRows(prevValue => {
            return !prevValue
        })
    }

    useEffect(() => {

        if (username && !userInfo.username.length) {
            myInfo()
        }

    }, [username])

    useEffect(() => {
        if (userInfo.username.length) {

            axios
                .get("/user/chipSummary/" + userInfo.username, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then((response) => {

                    if (response.data.success) {
                        setAllUsers(response.data.data)
                    }

                })
                .catch((err) => {
                    console.log(err);
                });

        }

    }, [userInfo, updateRows])

    let plus = null, minus = null

    if (allUsers.length) {
        plus = allUsers.filter(user => user.winnings > 0).map(user => {
            return <TableRow key={user.username}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.usertype === userInfo.usertype ? 'Parent A/C' : <span onClick={() => { ahead(user.username) }} className={classes.link}>{user.username + ' A/C'}</span>}</TableCell>
                <TableCell>{formatCoins(user.winnings)}</TableCell>
                <TableCell>
                    {user.usertype === userInfo.usertype ? null : <Button variant='contained' className={classes.btn} onClick={() => {
                        settlement(userInfo.username, user.username, user.winnings)
                    }}>Settlement</Button>}
                </TableCell>
            </TableRow>
        })

        minus = allUsers.filter(user => user.winnings <= 0).map(user => {
            return <TableRow key={user.username}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.usertype === userInfo.usertype ? 'Parent A/C' : <span onClick={() => { ahead(user.username) }} className={classes.link}>{user.username + ' A/C'}</span>}</TableCell>
                <TableCell>{formatCoins(user.winnings)}</TableCell>
                <TableCell>
                    {user.usertype === userInfo.usertype ? null : <Button variant='contained' className={classes.btn} onClick={() => {
                        settlement(userInfo.username, user.username, user.winnings)
                    }}>Settlement</Button>}
                </TableCell>
            </TableRow>
        })
    }

    return (
        <div className={classes.root}>
            <Modal open={open} onClose={onClose}>
                <Settlement
                    handleClose={onClose}
                    updateRows={updateChips}
                    uplink={settleInfo.uplink}
                    downlink={settleInfo.downlink}
                    chips={settleInfo.chips}
                />
            </Modal>
            <div className={classes.head}>
                <h3>Chip Summary</h3>
                <div className={classes.back} onClick={() => {
                    back()
                }}>back</div>
            </div>
            <div className={classes.box}>
                <div>
                    <h3 className={classes.heading}>PLUS ACCOUNT(Dene ha)</h3>
                    <TableContainer component={Paper}>
                        <Table className={classes.tableContainer}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Account</TableCell>
                                    <TableCell>Chips</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {plus}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>

                </div>
                <div>
                    <h3 className={classes.heading}>MINUS ACCOUNT(Lene ha)</h3>
                    <TableContainer component={Paper}>
                        <Table className={classes.tableContainer}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Account</TableCell>
                                    <TableCell>Chips</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {minus}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}