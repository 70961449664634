import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import classes from './FancyTable.module.css';
import axios from '../../../../../../axios-instance/backendAPI';
import { useSelector, useDispatch } from "react-redux";
import * as actions from '../../../../../../store/actions/index';
import logo from '../../../../../../assets/images/ball-loader.gif';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import Table from '../../../../../UI/TableNumber/Table';
import { formatCoins } from '../../../../../../helpers/common';

const defaultStakes = [
    {
        "key": 0,
        "label": "100",
        "stake": 100
    },
    {
        "key": 1,
        "label": "200",
        "stake": 200
    },
    {
        "key": 2,
        "label": "300",
        "stake": 300
    },
    {
        "key": 3,
        "label": "500",
        "stake": 500
    },
    {
        "key": 4,
        "label": "1000",
        "stake": 1000
    },
    {
        "key": 5,
        "label": "10000",
        "stake": 10000
    },
    {
        "key": 6,
        "label": "20000",
        "stake": 20000
    },
    {
        "key": 7,
        "label": "50000",
        "stake": 50000
    }
]

const bl = "Ball Running"
const sus = "SUSPENDED"

const FancyRow = (props) => {

    const [stake, setStake] = useState('')
    let btnRef = useRef();
    const [price, setPrice] = useState(0)
    const [size, setSize] = useState(0)
    const [selection, setSelection] = useState(null)
    const [bgColor, setBgColor] = useState(classes.lay1)
    const [activeblock, setActiveblock] = useState(null)
    const [exposure, setExposure] = useState(props.exposure ?? 0)
    const [showBook, setShowBook] = useState(props.showBook ?? false)
    const usertype = useSelector(state => state.auth.usertype)
    const isBetting = useSelector(state => state.update.isBetting)
    const token = useSelector(state => state.auth.token)
    const dispatch = useDispatch()

    useEffect(() => {
        setExposure(props.exposure); 
    }, [props.exposure]);

    const handleStakeChange = (money) => {

        if (stake === '') {
            setStake(money)
            return
        }
        if ((money + stake) <= 1000000) {
            setStake(money + stake)
        }
    }
    const setStakeManual = (event) => {
        if (event.target.value <= 1000000) {
            setStake(event.target.value ? parseFloat(event.target.value) : '');
        }
    }
    const setStakeTable = (value) => {
        if (value < 10 && ((stake * 10 + value) <= 1000000)) {
            setStake(stake * 10 + value)
        } else if (value >= 10) {
            if (stake * value <= 1000000) {
                setStake(stake * value)
            }
        }
    }
    const backspace = () => {
        if (stake < 10) {
            setStake('')
            return
        }
        const newStake = stake / 10
        const decimalValue = stake / 10 - Math.floor(stake / 10)
        setStake(newStake - decimalValue);
    }
    const incStake = () => {
        if (stake) {
            setStake(stake * 2);
        }
    }
    const decStake = () => {
        if (stake) {
            setStake(stake / 2);
        }
    }

    const placeBet = async () => {

        if (!parseFloat(stake)) {
            return;
        }
        else if (!navigator.onLine) {
            alert('Internet disconnected')
            return;
        }
        else if (props?.GameStatus?.toUpperCase() === sus || props?.GameStatus?.toUpperCase() === bl.toUpperCase()) {
            alertify.error('Invalid bet!')
            return;
        }

        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }

        dispatch(actions.setBettingStatus())

        const payload = {
            odds: parseFloat(size),
            user_rate: parseFloat(price),
            selection: selection,
            stake: parseFloat(stake),
            runnerName: props.name,
            runner: props.id.toString(),
            eventName: props.eventName,
            event: props.eventId,
            sport: props.sport,
            type: 'fancy'
        }
        console.time(props.name)
        axios.post('/user/fancy', payload, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                console.timeEnd(props.name)

                if (response.data.success) {
                    // audio.play();
                    const updatedUser = response.data.results.updatedUser
                    dispatch(actions.resetBettingStatus())
                    dispatch(actions.updateBalanceOrExposure(updatedUser.balance, updatedUser.exposure))
                    dispatch(actions.updateCurrentBets(updatedUser.bet))
                    props.handleClick(null)
                    setStake('')
                    setExposure(updatedUser.fancy_exposure)
                    setShowBook(true)
                    alertify.success(response.data.results.message);
                } else {
                    dispatch(actions.resetBettingStatus())
                    alertify.error(response.data.results.message);
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    let allStakes = null;

    if (props.stakes) {

        allStakes = props.stakes.map(stake => {
            return <div
                className={classes.textCenter}
                key={stake.key}
            >
                <button
                    onClick={() => {
                        handleStakeChange(stake.stake)
                    }}>{stake.label}</button>
            </div>
        })
    }
    else {

        allStakes = defaultStakes.map(stake => {
            return <div
                className={classes.textCenter}
                key={stake.key}
            >
                <button style={{ cursor: 'pointer' }} onClick={() => { handleStakeChange(stake.stake) }}>{stake.label}</button>
            </div>
        })
    }

    let value = <>
        <div className={[classes.lay, props.isActive && activeblock === 2 ? classes.activeLay : ""].join(' ')}
            onClick={() => {
                if (parseFloat(props.backPrice1) && usertype === '5') {
                    props.handleClick(props.id);
                    setPrice(props.layPrice1)
                    setSize(props.laySize1)
                    setSelection('lay')
                    setBgColor(classes.lowerLay)
                    setActiveblock(2)
                }
            }}
        >
            <div> {props.layPrice1}</div>
            <div className={classes.tiny}> {props.laySize1}</div>
        </div>
        <div className={[classes.back, props.isActive && activeblock === 1 ? classes.activeBack : ""].join(' ')}
            onClick={() => {

                if (parseFloat(props.backPrice1) && usertype === '5') {
                    props.handleClick(props.id);
                    setPrice(props.backPrice1)
                    setSize(props.backSize1)
                    setSelection('back')
                    setBgColor(classes.lowerBack)
                    setActiveblock(1)
                }

            }}
        >
            <div> {props.backPrice1}</div>
            <div className={classes.tiny}> {props.backSize1} </div>
        </div>
        <div className={classes.mobile}>{props.min + '/' + props.max}</div>
    </>

    if (props?.GameStatus?.toUpperCase() === bl.toUpperCase()) {
        value = (
            <>
                <div className={classes.deactivate} style={{ justifyContent: 'end' }}>BALL RU</div>
                <div className={classes.deactivate} style={{ justifyContent: 'start' }}>NNING</div>
            </>
        )
    }
    if (props?.GameStatus?.toUpperCase() === sus) {
        value = (
            <>
                <div className={classes.deactivate} style={{ justifyContent: 'end' }}>SUSPE</div>
                <div className={classes.deactivate} style={{ justifyContent: 'start' }}>NDED</div>
            </>
        )
    }

    return (
        <>
            <div className={classes.midGrid} >
                <div className={classes.textLeft}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ textAlign: 'left' }} > {props.name} </div>
                        <div className={classes.tiny} style={{ textAlign: 'left' }}><span style={{ color: 'red' }}>{`-->${formatCoins(exposure)}`}</span></div>
                    </div>
                   {showBook && <button className={classes.bookBtn} onClick={() => {
                        props.showModal(props.id)
                    }}>Book</button>}
                </div>
                <div className={classes.odds}>
                    {value}
                </div>
            </div>
            <div className={[props.isActive ? classes.dBlock : classes.dNone, bgColor, isBetting ? classes.disabled : ''].join(' ')}>
               {isBetting && <div className={classes.loadingOverlay}>
                    <img src={logo} alt="Loading..." className={classes.loadingGif} />
                </div>} 
                <div className={classes.lowerGrid}>
                    <div className={classes.textCenter}>
                        <button
                            className={classes.cancel}
                            onClick={() => {
                                props.handleClick(null);
                                setStake('')
                            }}>Cancel</button>
                    </div>
                    {props.code === 1 ? 
                        <div className={classes.textCenter}>
                            <p>
                                <input value={price} className={classes.editableWeight} onChange={(event)=>setPrice(event.target.value)}/>
                                <input value={size} className={classes.editableWeight} onChange={(event)=>setSize(event.target.value)}/>
                            </p>
                        </div>
                        :<div className={classes.textCenter}><p className={classes.weight}><span>{price}</span><span>{size}</span></p></div>}
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <button
                                    className={classes.dec}
                                    onClick={decStake}>-
                                </button>
                            </div>
                            <input
                                className={classes.stake}
                                type='number'
                                value={stake}
                                onChange={setStakeManual}
                                placeholder='0.00' />
                            <div>
                                <button
                                    className={classes.inc}
                                    onClick={incStake}>+</button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.textCenter}>
                        <button
                            disabled={isBetting}
                            onClick={placeBet}
                            className={stake <= 0 || isBetting ? classes.inactiveBet : classes.activeBet}>
                            Place Bet
                        </button>
                    </div>
                </div>
                <div className={classes.bottomGrid}>
                    {allStakes}
                </div>
                <Table
                    setStakeTable={setStakeTable}
                    backspace={backspace}
                />
            </div>
        </>
    )
}


export default FancyRow