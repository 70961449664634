import React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import axios from "../../../../../axios-instance/backendAPI";
import { Formik } from "formik";
import * as yup from "yup";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    maxWidth: 250,
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
    },
  },
  inputLabel: {
    whiteSpace: 'nowrap',
  }
}));

const schema = yup.object({
  partnership: yup
    .number()
    .integer(<p style={{ color: "red" }}>Partnership must be an integer</p>)
    .required(<p style={{ color: "red" }}>Partnership required</p>)
    .min(0,<p style={{ color: "red" }}>
      Can't be less than 0!
    </p>)
    .max(100,<p style={{ color: "red" }}>
      Can't be greater than 100!
    </p>),
  bookmakerCommission: yup
    .number()
    .required(<p style={{ color: "red" }}>Bookmaker commission required</p>)
    .oneOf([0, 1], <p style={{ color: "red" }}>Invalid commission value</p>),
  fancyCommission: yup
    .number()
    .required(<p style={{ color: "red" }}>Fancy commission required</p>)
    .oneOf([0, 1], <p style={{ color: "red" }}>Invalid commission value</p>),
  password: yup
    .string()
    .required(<p style={{ color: "red" }}>Password required</p>),
});

const Partnership = (props) => {
  const token = useSelector(state => state.auth.token)
  const classes = useStyles()

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data, { setErrors, resetForm, setSubmitting }) => {
        setSubmitting(true);

        const payload = {
          username: props.username,
          partnership: data.partnership,
          bookmakerCommission: data.bookmakerCommission,
          fancyCommission: data.fancyCommission,
          password: data.password
        };

        axios
          .patch("/user/resetPartnership", payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data.success) {
              alertify.success(response.data.message);
              props.updateRows();
              resetForm({
                partnership: "",
                passsword: "",
              });
              props.handleClose();
            } else {
              setErrors({
                password: (
                  <span style={{ color: "red" }}>{response.data.message}</span>
                ),
              });
            }
            setSubmitting(false);
          })
          .catch((error) => {
            console.log(error);
            setSubmitting(false);
          });
      }}
      initialValues={{
        partnership: props.partnership,
        bookmakerCommission: props.bookmaker_com,
        fancyCommission: props.fancy_com, 
        password: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        touched,
        errors,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <h3>Partnership/Commission of {props.username}</h3>
          <hr />
          <Grid
            className={props.gridy}
            container
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                label="Partnership"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={values.partnership}
                name="partnership"
                type="number"
                autoFocus
                className={classes.inputField}
              />
              {touched.partnership && errors.partnership}
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" size="small" className={classes.inputField}>
                <InputLabel className={classes.inputLabel}>Bookmaker Commission</InputLabel>
                <Select
                  name="bookmakerCommission"
                  value={values.bookmakerCommission}
                  onChange={handleChange}
                  label="Bookmaker Commission"
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>{props.commission.bookmaker_com}%</MenuItem>
                </Select>
                {touched.bookmakerCommission && errors.bookmakerCommission}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" size="small" className={classes.inputField}>
                <InputLabel>Fancy Commission</InputLabel>
                <Select
                  name="fancyCommission"
                  value={values.fancyCommission}
                  onChange={handleChange}
                  label="Fancy Commission"
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>{props.commission.fancy_com}%</MenuItem>
                </Select>
                {touched.fancyCommission && errors.fancyCommission}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={values.password}
                name="password"
                type="password"
                className={classes.inputField}
              />
              {touched.password && errors.password}
            </Grid>
          </Grid>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            autoFocus
            style={{marginTop: '5px'}}
          >
            Reset
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default Partnership;