export const UPDATE_BALANCE = 'UPDATE_BALANCE';
export const UPDATE_MARKET_EXPOSURE = 'UPDATE_MARKET_EXPOSURE'
export const UPDATE_FANCY_EXPOSURE = 'UPDATE_FANCY_EXPOSURE'
export const UPDATE_CURRENT_BETS = 'UPDATE_CURRENT_BETS'
export const SET_BETTING_STATUS = 'SET_BETTING_STATUS'
export const RESET_BETTING_STATUS = 'RESET_BETTING_STATUS'
export const RESET_MESSAGE = 'RESET_MESSAGE'
export const CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION'

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';