import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: 'var(--header_color)',
    color: 'white',
    textAlign: 'center',
    padding: theme.spacing(1),
    fontSize: 'var(--small_font)',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      © {currentYear} Maxx 100. All rights reserved.
    </footer>
  );
};

export default Footer;
