import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper
} from "@material-ui/core";
import DateTime from "../../../../UI/DateTime/DateTime";
import axios from "../../../../../axios-instance/backendAPI";
import { useLocation } from "react-router-dom";
import moment from "moment";
import BetHistory from '../../BetHistory/BetHistory'
import { useSelector } from "react-redux";
import { formatCoins } from "../../../../../helpers/common";

const columns = [
  {
    id: "created_at",
    label: "Date",
    align: "center",
    minWidth: 170
  },
  {
    id: "description",
    label: "Description",
    align: "center",
    minWidth: 400
  },
  {
    id: "deposited",
    label: "Credit",
    minWidth: 140,
    align: "center",
  },
  {
    id: "withdrawn",
    label: "Debit",
    minWidth: 140,
    align: "center",
  },
  {
    id: "balance",
    label: "Balance",
    minWidth: 170,
    align: "center",
  },
  {
    id: "comission",
    label: "Com Balance",
    minWidth: 170,
    align: "center",
  },
  {
    id: "exposure",
    label: "Exposure",
    minWidth: 170,
    align: "center",
  },
];

export default function AccountStatement() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setrows] = useState([])
  const location = useLocation();
  const [bets, setBets] = useState(false)
  const [eventInfo, setEventInfo] = useState(null)
  const [fromTo, setFromTo] = useState(null)
  const [dateCache, setDateCache] = useState(null)
  const [userInfo, setUserInfo] = useState()
  const [username, setUsername] = useState('')
  const token = useSelector(state => state.auth.token)
  const usertype = useSelector(state => state.auth.usertype)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const trimDes = (value, type) => {

    let index = value.length

    if (type === 'pl') {
      index = value.lastIndexOf('//')
    }

    return value.slice(0, index)
  }
  const showBet = (value) => {

    const query = new URLSearchParams(location.search);
    let username

    for (let param of query.entries()) {

      if (param[0] === 'username') {
        username = param[1]
      }
    }

    const breakInfo = value.split('//')
    const isCasino = breakInfo[0].toLowerCase() === 'casino'

    let event = isCasino ? breakInfo[1] : breakInfo[3]
    let market = isCasino ? null : breakInfo[4]
    let eventId = breakInfo[breakInfo.length - 1]

    const cur_event = {
      event: event,
      market: market,
      username: username,
      eventId: eventId
    }

    setEventInfo(cur_event)
    setBets(true)
    setDateCache(fromTo)
  }

  const onSubmit = (from, to, value) => {

    let fromDate = from
    let toDate = to

    if (dateCache) {

      fromDate = dateCache.from
      toDate = dateCache.to
      setDateCache(null)
    }

    const query = new URLSearchParams(location.search);
    let username, usertype

    for (let param of query.entries()) {

      if (param[0] === 'username') {
        username = param[1]
      } else if (param[0] === 'usertype') {
        usertype = param[1]
      }
    }

    setUsername(username)

    axios.get(`/user/userAccountStatement/${value}/${username}/${usertype}/${fromDate}/${toDate}`, { headers: { Authorization: `Bearer ${token}`} })
      .then(response => {
        if (response.data.success) {
          setrows(response.data.data);
          const cur_date = {
            from: from,
            to: to
          }
          setFromTo(cur_date)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {

    const query = new URLSearchParams(location.search);
    let username, usertype
    for (let param of query.entries()) {

      if (param[0] === 'username') {
        username = param[1]
      } else if (param[0] === 'usertype') {
        usertype = param[1]
      }
    }
    setUserInfo({ username: username, usertype: usertype })
  }, [location.search])

  const filter = [
    { value: 'All', label: 'All' },
    { value: 'fc', label: 'Free Chips' },
    { value: 'se', label: 'Settlement' },
    { value: 'pl', label: 'Profit Loss' }
  ]

  let table = <>
    <DateTime
      onSubmit={onSubmit}
      filter={filter}
      name={`Account Statement of ${username}`}
      dateCache={dateCache}
      userInfo={userInfo}
    />
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="none" className={classes.head}>
              S.No.
              </TableCell>
            {columns.map((column) => {

              if(userInfo?.usertype !== '5' && column.id ==='exposure') {
                return null
              }else if(usertype === '1' && column.id === 'comission') {
                return null
              }

              return <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                className={classes.head}
                padding="none"
              >
                {column.label}
              </TableCell>
            }
          )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!rows?.length ? <TableRow><TableCell colSpan={columns.length}>No data</TableCell></TableRow> : null}
          {rows
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index + 1}>
                  <TableCell key={index + 1} align="center">
                    {index + 1}
                  </TableCell>
                  {columns.map((column) => {
                      if(userInfo?.usertype !== '5' && column.id ==='exposure') {
                        return null
                      }else if(usertype === '1' && column.id === 'comission') {
                        return null
                      }
                    const value = row[column.id];
                    return column.id === 'created_at' ?
                      <TableCell key={column.id} align={column.align}>
                        {moment(value).format('DD-MM-YYYY HH:mm:ss')}
                      </TableCell>
                      : (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ color: column.id === 'description' ? 'blue' : 'black', cursor: column.id === 'description' ? 'pointer' : 'auto' }}
                          onClick={() => {
                            if (column.id === 'description' && value.includes('//')) {
                              showBet(value)
                            }
                          }}>
                          {column.id === 'description' ? trimDes(value, row.type)
                            : column.format && typeof value === "number"
                              ? column.format(value)
                              : value!==null ? formatCoins(value) : '-'}
                        </TableCell>
                      );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 50, 100]}
      component="div"
      count={rows?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      className={classes.pagination}
    />
  </>

  if (bets) {
    table =
      <Paper className={classes.paper}>
        <BetHistory eventInfo={eventInfo} fromTo={fromTo} flag={1} />
      </Paper>

  }

  return (
    <div className={classes.outerBox}>
      <div>{bets ?
        <p style={{ display: 'flex', justifyContent: 'space-between', marginRight: '12px' }}>
          <span style={{ fontWeight: 'bold' }}>Bet history</span>
          <span style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => {
            setBets(false)
          }}>Back</span>
        </p> : null}
      </div>
      {table}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  outerBox: {
    padding: '0 10px 10px 10px'
  },
  root: {
    width: "100%",
    padding: "none",
  },
  container: {
    maxHeight: 440,
    margin: '20px 0 0',
    '& .MuiTableCell-root': {
      fontSize: 'var(--large_font)', // Adjust the font size as needed
    },
    [theme.breakpoints.down("sm")]: {
        '& .MuiTableCell-root': {
            fontSize: 'var(--small_font)', // Adjust the font size as needed
        },
    },
  },
  pagination: {
    '& .MuiTablePagination-caption': {
        fontSize: 'var(--large_font)', // Font size for caption
    },
    '& .MuiTablePagination-input': {
        fontSize: 'var(--large_font)', // Font size for input
    },
    [theme.breakpoints.down("sm")]: {
        '& .MuiTablePagination-caption': {
            fontSize: 'var(--small_font)', // Font size for caption
        },
        '& .MuiTablePagination-input': {
            fontSize: 'var(--small_font)', // Font size for input
        }, 
    }
  },
  head: {
    fontWeight: "bold",
    border: "1px solid #8a8a82",
    backgroundColor: "#e7e7e7",
    minWidth: '80px'
  },

}));
