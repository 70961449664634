import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../../../axios-instance/backendAPI";
import { Button, Input, InputLabel } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../../../../store/actions/index';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export default function Settlement(props) {

    const dispatch = useDispatch()
    const { handleSubmit } = useForm();
    let btnRef = useRef();
    const username = useSelector((state)=> state.auth.username);
    const usertype = useSelector((state)=> state.auth.usertype);
    const balance = useSelector((state) => state.update.balance);
    const exposure = useSelector((state) => state.update.exposure);
    const token = useSelector(state => state.auth.token)

    const onSubmit = () => {
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        const payload = {
            uplink: props.uplink,
            downlink: props.downlink
        };
        axios
            .put("/user/settlement", payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                if (response.data.success) {
                    alertify.success(response.data.message);
                    props.updateRows();
                    props.handleClose()
                    let newBalance, newExposure;
                    if(props.uplink === username){
                        const isAdmin = username?.toLowerCase() === 'admin';
                        if(isAdmin){
                            newBalance = balance + (props.chips > 0 ? props.chips : 0)
                            newExposure = exposure - (props.chips > 0 ? 0 : props.chips) 
                        }else if(usertype === '4') {
                            newBalance = balance + props.chips
                            newExposure = exposure - props.chips 
                        }else {
                            newBalance = balance - props.chips
                            newExposure = exposure + props.chips 
                        }
                        dispatch(actions.updateBalanceOrExposure(newBalance, newExposure));
                    }
                } else {
                    alertify.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <>
            <h3>Settlement between {props.uplink} and {props.downlink}</h3>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputLabel htmlFor="chips">Chips</InputLabel>
                <Input
                    name="chips"
                    defaultValue={props.chips}
                    disabled
                />
                <br />
                <Button
                    type="submit"
                    variant='contained'
                    ref={btnRef}
                    style={{ margin: '10px 0' }}
                >Submit</Button>
            </form>
        </>
    );
}