import React from 'react'
import Navbar from './Navbar/Navbar'
import TabBar from './TabBar/TabBar'
export default function Navigation() {
    return (
        <>
            <Navbar/>
            <TabBar/>
        </>
    )
}
