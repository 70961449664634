import axios from 'axios'
import config from '../config'

const instance = axios.create({ baseURL: config.NODE_ENV === 'development' ? config.API_URL : config.PROD_URL });

instance.interceptors.response.use((response) => {
    if(response.data.message ===  "Invalid Token...")
        window.location.href = '/login'
    else if(response.data.password_changed && window.location.pathname !== '/changePassword'){
        window.location.href = '/changePassword'
    }
    return response;
}, (error) => {
    return Promise.reject(error.message);
});

export default instance;