import React, { useEffect, useState } from 'react'
import axios from '../../../../axios-instance/backendAPI';
import Event from '../Dashboard/Event/Event';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Sport = () => {
    const [matches, setMatches] = useState();
    const params = useParams();
    const token = useSelector(state => state.auth.token)

    useEffect(()=>{
        axios.get(`/user/getMatchesBySportWithOdds/${params.id}`, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
            if (response.data.success && Array.isArray(response.data.data))
              setMatches(response.data.data)
        })
        .catch(error => {
            setMatches([])
            console.log(error);
        })
    },[])

    return <Event id={params.id} index={2} matches={matches} />
}

export default Sport