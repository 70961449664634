import React, { useState, useEffect } from 'react'
import { TableBody, TableRow, TableCell, TableContainer, Table, Paper, TableHead } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import axios from '../../../../../../axios-instance/backendAPI';
import { useSelector } from 'react-redux';
import { formatCoins } from '../../../../../../helpers/common';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px'
    },
    action: {
        cursor: 'pointer',
        fontSize: 'var(--medium_font)',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)',
        }
    },
    total: {
        fontWeight: 700
    },
    text: {
        fontSize: 'var(--medium_font)',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'var(--small_font)',
        }
    },
    subHeading: {
        marginLeft: '5px',
        display: 'flex',
        alignItems: 'center',
        color: 'var(--header_color)',
        fontWeight: 700
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px'
    }
}))

export default function MarketAnalysis(props) {

    const classes = useStyles()
    const [allDownlinks, setAllDownlinks] = useState([])
    const [update, setUpdate] = useState(false)
    const [isChecked, setIsChecked] = useState(true);
    const [isCommissionChecked, setIsCommissionChecked] = useState(true);
    const token = useSelector(state => state.auth.token)

    const handleCheckboxChange = () => {
      setIsChecked(prevValue=>!prevValue);
    };

    const handleCommissionCheckboxChange = () => {
        setIsCommissionChecked(prevValue=>!prevValue);
      };

    useEffect(() => {
        getMarketAnalysis()
    }, [props.marketId, update, props.username])

    const getMarketAnalysis = () => {

        axios.get(`/user/getMarketAnalysis/${props.eventId}/${props.marketId}/${props.username}/${props.usertype}`, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if (response.data.success) {
                    setAllDownlinks(response.data.data)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const changeUpdate = () => {
        setUpdate(prevValue=>!prevValue)
    }

    let head = null, body = null, total = [];
    const runners = allDownlinks?.[0]?.runners
    if (runners?.length) {
        runners.forEach(() => total.push(0));

        head = <TableHead>
            <TableRow>
                <TableCell>Username</TableCell>
                {runners.sort((a, b) => a.sort_priority - b.sort_priority).map(runner => {
                    return <TableCell key={runner.selectionId}>{runner.name}</TableCell>
                })}
            </TableRow>
        </TableHead>

        body = <TableBody>
            {allDownlinks?.map(user => {

                let i = 0

                if (user.runners)
                    user.runners = user.runners.sort((a, b) => a.sort_priority - b.sort_priority)

                const multiplication_factor = isChecked ? user.partnership/100 : 1

                return <TableRow
                    style={{ cursor: 'pointer', fontSize: '0.7rem' }}
                    key={user.downlink}
                >
                    <TableCell
                        onClick={() => {
                            props.ahead(user.downlink)
                        }}
                        className={classes.text}
                    >{user.downlink}</TableCell>
                    {user.runners ? user.runners.map(runner => {
                        const net_profit = isCommissionChecked ? runner.net_profit_wc : runner.net_profit
                        total[i] = total[i++] + net_profit * multiplication_factor
                        return <TableCell
                            key={runner.selectionId}
                            className={classes.total}
                            style={{ color: net_profit >= 0 ? 'green' : 'red' }}
                        >{formatCoins(net_profit * multiplication_factor)}</TableCell>
                    }) : <TableCell> No Bets found</TableCell>}
                </TableRow>
            })}
            <TableRow>
                <TableCell className={classes.total}>Total</TableCell>
                {total.map((runnerTotal, index) => {
                    return <TableCell
                        key={index}
                        className={classes.total}
                        style={{ color: runnerTotal >= 0 ? 'green' : 'red' }}
                    >{formatCoins(runnerTotal)}</TableCell>
                })}
            </TableRow>
        </TableBody>
    }

    return (
        <div>
            <div className={classes.root}>
                <div className={classes.action} onClick={() => {
                    changeUpdate()
                }}>
                  Current Position of <b>{props.marketName}</b> <i className="fas fa-sync" ></i>
                </div>
                <div className={classes.action} onClick={() => {
                    props.back()
                }}>
                    <i className="fas fa-backspace"></i> Back
                </div>
            </div>
            <div className={classes.subHeading}>
                <div className={classes.option}>
                    <span className={classes.text}>Partnership</span> 
                    <input 
                        type='checkbox'
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                </div>
                <div className={classes.option}>
                    <span className={classes.text}>Commission</span> 
                    <input 
                        type='checkbox'
                        checked={isCommissionChecked}
                        onChange={handleCommissionCheckboxChange}
                    />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    {head}
                    {body}
                </Table>
            </TableContainer>
        </div>
    )
}
