import React, { useState, useEffect, useRef } from 'react'
import classes from './FancyTable.module.css'
import back_axios from '../../../../../../axios-instance/backendAPI'
import FancyRow from './FancyRow'
import { useSelector } from "react-redux";
import { Paper } from '@material-ui/core'
import { formatCoins } from '../../../../../../helpers/common';

const Fancy = (props) => {

    const [sessions, setSessions] = useState(null)
    const [fancySettings, setFancySettings] = useState({
        max: 0,
        min: 0,
        status: 'on'
    })
    const ref = useRef()
    const token = useSelector(state => state.auth.token)
    const usertype = useSelector(state => state.auth.usertype)
    const socket = props.socket

    const updateSessions = (prevSession, updatedSession) => {
        const oldSessions = [...prevSession]
        updatedSession.forEach(session => {
            const index = oldSessions.findIndex(old_session => old_session.SelectionId === session.SelectionId)
            session.exposure = index === -1 ? 0 : oldSessions[index].exposure
            session.commission = index === -1 ? 0 : oldSessions[index].commission
        })
        return updatedSession
    }

    useEffect(() => {

        if (socket) {

            const key = 'Fancy/Auto';
            let counter = 1;

            socket.on(`${key}/${props.matchId}`, async (data) => {
                if (!ref.current && data) {
                    setInitialSessions(data);
                    ref.current = true;
                }

                if (Array.isArray(data) && data.length) {
                    const shouldUpdateExposure = counter % 9 === 0;  // Update after receiving 8 sessions
                    if (shouldUpdateExposure && usertype !== '5') {
                        counter = 0;
                        const sessionData = await getSessionInfo(data);
                        sessionData.sessions && setSessions(sessionData.sessions);
                    } else {
                        counter++;
                        setSessions((prevSession) => {
                            if (prevSession)
                                return updateSessions(prevSession, data);
                            return prevSession;
                        });
                    }

                }
            });

            // Subscribe to the fancy
            socket.emit(key, props.matchId);
        }
    }, []);

    const getSessionInfo = async (data) => {

        try {
            const payload = {
                event_id: props.matchId,
                sessions: data
            }

            const sessionsData = await back_axios.post('/user/getFancyBookAndSettings', payload, { headers: { Authorization: `Bearer ${token}` } })
            if (sessionsData.data.success)
                return sessionsData.data.data

            return {}
        } catch (err) {
            console.log(err)
            return {}
        }

    }

    const setInitialSessions = async (data) => {

        const sessionData = await getSessionInfo(data);
        sessionData.sessions && setSessions(sessionData.sessions);
        sessionData.session_restrictions && setFancySettings(sessionData.session_restrictions);

    }

    let fancyHead = null, fancyRow = null;
    if (Array.isArray(sessions) && sessions.length) {
        fancyHead = (
            <>
                <div className={classes.headGrid}>
                    <div className={classes.tableName}>Fancy Bet</div>
                    <div className={classes.tableName}>  Min/Max :- <span>{formatCoins(fancySettings.min) + '/' + formatCoins(fancySettings.max)}</span></div>
                </div>

                <div className={classes.upperGrid}>
                    <div></div>
                    <div className={classes.odds}>
                        <div className={[classes.textCenter, classes.no].join(' ')}>No</div>
                        <div className={[classes.textCenter, classes.yes].join(' ')}>Yes</div>
                        <div className={[classes.textCenter, classes.mobile].join(' ')}></div>
                    </div>
                </div>
            </>);

        fancyRow = sessions
        .filter(session => !session.RunnerName.toLowerCase().includes('run bhav') && (!session.RunnerName.endsWith('2') || props.isTestMatch))
        .map(session => {
            return <FancyRow
                key={session.SelectionId}
                id={session.SelectionId}
                name={session.RunnerName}
                layPrice1={session.LayPrice1}
                laySize1={session.LaySize1}
                backPrice1={session.BackPrice1}
                backSize1={session.BackSize1}
                exposure={session.exposure-session.commission}
                showBook={session.showBook}
                isActive={session.SelectionId === props.activeId}
                handleClick={props.handleClick}
                showModal={props.showModal}
                GameStatus={session.GameStatus}
                max={fancySettings.max}
                min={fancySettings.min}
                eventId={props.matchId}
                sport={props.sport}
                eventName={props.eventName}
                stakes={props.stakes}
                code={props.code}
            />
        })
    }

    return (
        <Paper className={classes.Fancy}>
            {fancyHead}
            {fancyRow}
        </Paper>
    )
}

export default Fancy