import React, { useEffect, useRef, useState } from 'react'
import classes from '../MarketTable.module.css'

export default function OddsBlock(props) {

    const ref = useRef(false)
    const [highlight, setHighlight] = useState(false)
    const lay = props.availableToLay ? props.availableToLay[props.num] : null
    const layPrice = lay && lay.price ? lay.price : null
    const laySize = lay ? lay.size : null

    useEffect(() => {

        if (ref.current) {
            setHighlight(true)
            setTimeout(() => {
                setHighlight(false)
            }, 300);
        }
        ref.current = true
    }, [layPrice, laySize])

    return (
        <div className={[classes.element, highlight ? classes.highlight : ''].join(' ')}>
            <div> {layPrice ? layPrice : 0}</div>
            <div className={props.tiny}> {laySize && laySize!=='0' ? (laySize > 1000 ? (laySize / 1000).toFixed(2) + 'k' : laySize) : null}</div>
        </div>
    )
}
