import React from "react";
import ViewInfoDetail from "./ViewInfoDetail/ViewInfoDetail";
export default function ViewInfo(props) {
  const info = ["User", "Soccer", "Tennis", "Cricket", "Fancy","Book Commission"]
  return (
    <div className={props.paper}>
      <div style={{ backgroundColor: 'black', height: '30px', color: 'white', fontSize: '20px', padding: '5px 10px', fontWeight: 'bold' }}>Account Information</div>

      {
        info.map((id, index) => {
          return <ViewInfoDetail
            key={index}
            username={props.username}
            usertype={props.usertype}
            commission={props.commission}
            updateRows={props.updateRows}
            info={id}
            handleClose={props.handleClose} />
        })
      }

    </div>
  );
}
